import axios from "axios";

//const API = axios.create({ baseURL: "http://localhost:5000", withCredentials: true });
const API = axios.create({ baseURL: "https://api.flashteller.com", withCredentials: true });

API.interceptors.request.use((req) => {
	if (localStorage.getItem("profile")) {
		req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem("profile")).token}`;
	}
	return req;
});

//BITCOIN MAIN NETWORK
export const requestAgentWithdrawalBTC = (agentid, invoice, amount, merchant, twoFactorAuth, twoFactorPin, token) =>
	API.post("/cashier/requestagentwithdrawalbtc", {
		agentid: agentid,
		invoice: invoice,
		amount: amount,
		merchant: merchant,
		twoFactorAuth,
		twoFactorPin,
		token,
	});
export const getPreviousRequestAgentWithdrawalBTC = (agentid, merchant, token) =>
	API.post("/cashier/previousrequestagentwithdrawalbtc", { agentid: agentid, merchant: merchant, token });

export const responseWithdrawalBTC = (withdrawalId, response, token) =>
	API.post("/cashier/responseplayerwithdrawalbtc", { withdrawalId, response, token });
export const requestPreviousWithdrawalAgentBTC = (agentId, merchant) =>
	API.post("/cashier/requestPreviousBTCWithdrawalAgent", { agentId: agentId, merchant: merchant });

//ETH
export const responseWithdrawalAgentETH = (withdrawalId, response, token) =>
	API.post("/cashier/responseplayerwithdrawaleth", { withdrawalId, response, token });
export const requestAgentWithdrawalETH = (agentid, address, amount, merchant, cryptoCurrency, token) =>
	API.post("/cashier/requestagentwithdrawaleth", {
		agentid: agentid,
		address: address,
		amount: amount,
		merchant: merchant,
		cryptoCurrency: cryptoCurrency,
		token,
	});

//TRON
export const requestAgentWithdrawalUSDTTRON = (agentid, address, amount, merchant, token) =>
	API.post("/cashier/requestagentwithdrawalusdttron", { agentid: agentid, address: address, amount: amount, merchant: merchant, token });
export const responseWithdrawalAgentUSDTTRON = (withdrawalId, response, token) =>
	API.post("/cashier/responseplayerwithdrawalusdttron", { withdrawalId, response, token });
