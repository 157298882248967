import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

//Core
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import { TableContainer, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import OkDialog from "../Dialogs/OkDialog";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../Dialogs/ConfirmRequest";

//Styles
import styles from "../../assets/styles/components/agentsListStyle";

//Functions
import { getRequestWithdrawals, setWithdrawalRequestAnswer } from "../../actions/agent";
import { LOGIN } from "../../constants/actionTypes";

const useStyles = makeStyles(styles);

const WithdrawalsRequest = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const { agentId, merchant } = props;
    const dispatch = useDispatch();
    const now = moment();
    let search = "";

    let start = 0;
    let startDate = now.startOf("day");

    const [length, setLength] = useState(10);
    const [page, setPage] = useState(0);
    const [disabeButton, setDisableButton] = useState(false);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [withdrawalsList, setWithdrawalsList] = useState(null);
    const token = useSelector((state) => state?.login?.loginData?.token);
    const [okDialog, setOkDialog] = useState({ isOpen: false, title: "", subTitle: "" });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "", messageBold: false });

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getRequestWithdrawals(agentId, merchant, start, length, startDate, search, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (!res.error) {
                    setWithdrawalsList(res?.data);
                    setRecordsTotal(res?.count);
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, 10));
        setPage(0);
    };

    const tableHead = ["Date", "Customer", "Agent", "Type", "Network", "Crypto Amount", "Exchange", "$USD", "Fees", "Total", "", ""];

    useEffect(() => {
        dispatch(
            getRequestWithdrawals(agentId, merchant, start, length, startDate, search, token, (res) => {
                console.log(res.data);
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (!res.error) {
                    setWithdrawalsList(res?.data);
                    setRecordsTotal(res?.count);
                }
            })
        );
    }, [start, length, dispatch]);

    const searchChange = (e) => {
        e.preventDefault();
        search = e.target.value;
        dispatch(
            getRequestWithdrawals(agentId, merchant, start, length, startDate, search, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (!res.error) {
                    setWithdrawalsList(res?.data);
                    setRecordsTotal(res?.count);
                }
            })
        );
    };

    const AcceptWithdrawal = (id, e) => {
        setDisableButton(true);
        setConfirmDialog({
            isOpen: true,
            title: "Withdrawal Process",
            subTitle: "Are you sure to accept withdrawal request?",
            onConfirm: async () => {
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });
                dispatch(
                    setWithdrawalRequestAnswer(id, "Accept", token, (res) => {
                        if (!res.error) {
                            dispatch(
                                getRequestWithdrawals(agentId, merchant, start, length, startDate, search, token, (res) => {
                                    setDisableButton(false);
                                    if (res.error) {
                                        if (res.message === "Token Expire") {
                                            dispatch({ type: LOGIN, res });
                                            history.push("/");
                                        }
                                    }
                                    if (!res.error) {
                                        setWithdrawalsList(res?.data);
                                        setRecordsTotal(res?.count);
                                    }
                                })
                            );
                        } else {
                            setOkDialog({
                                isOpen: true,
                                title: "Error on withdrawal.",
                                subTitle: res?.message,
                                onConfirm: () => {
                                    setOkDialog({
                                        ...okDialog,
                                        isOpen: false,
                                    });
                                },
                            });
                            setDisableButton(false);
                        }
                    })
                );
            },
            onCancel: () => {
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });
                setDisableButton(false);
                return;
            },
        });
    };

    const RejectWithdrawal = (id) => {
        setDisableButton(true);
        setConfirmDialog({
            isOpen: true,
            title: "Withdrawal Process",
            subTitle: "Are you sure to reject withdrawal request?",
            onConfirm: async () => {
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });
                dispatch(
                    setWithdrawalRequestAnswer(id, "Reject", token, (res) => {
                        //console.log("Request reject response : ", res);
                        if (res.error) {
                            if (res.message === "Token Expire") {
                                dispatch({ type: LOGIN, res });
                                history.push("/");
                            }
                            setDisableButton(false);
                        }
                        //console.log("REJECT ANSWER", res);
                        if (!res.error) {
                            //console.log("REJECT ANSWER dispatch");
                            dispatch(
                                getRequestWithdrawals(agentId, merchant, start, length, startDate, search, token, (respuesta) => {
                                    setDisableButton(false);
                                    //console.log("REJECT ANSWER refresh", respuesta);
                                    if (respuesta.error) {
                                        if (respuesta.message === "Token Expire") {
                                            dispatch({ type: LOGIN, respuesta });
                                            history.push("/");
                                        }
                                    }
                                    if (!respuesta.error) {
                                        setWithdrawalsList(respuesta?.data);
                                        setRecordsTotal(respuesta?.count);
                                    }
                                })
                            );
                        }
                    })
                );
            },
            onCancel: () => {
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });
                setDisableButton(false);
                return;
            },
        });
    };

    if (withdrawalsList) {
        if (withdrawalsList.length === 0) {
            return (
                <TableContainer>
                    <Table className={classes.tableResponsive}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={8} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                                    <TextField
                                        label="Search"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={searchChange}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.tableHeadRow}>
                                <TableCell colSpan={8} key={"norecords1"}>
                                    <div style={{ textAlign: "center" }}>No records found</div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            );
        } else {
            return (
                <TableContainer>
                    <Table className={classes.tableResponsive}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={9} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                                    <TextField
                                        label="Search"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={searchChange}
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow className={classes.tableHeadRow}>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                            {prop}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {withdrawalsList.map((payout, index) => (
                                <TableRow key={index} className={classes.tableRow}>
                                    <TableCell className={classes.tableCell}>{moment(payout.createdAt).format("MM/DD/YYYY hh:mm")}</TableCell>
                                    <TableCell className={classes.tableCell}>{payout.player}</TableCell>
                                    <TableCell className={classes.tableCell}>{payout.agent}</TableCell>
                                    <TableCell className={classes.tableCell}>{payout.isLN === true && payout.cryptoCurrency === "BTC" ? "Lightning" : payout.isLN === false && payout.cryptoCurrency === "BTC" ? "BTC Mainnet" : payout.cryptoCurrency}</TableCell>
                                    <TableCell className={classes.tableCell}>{payout.network === "BITCOIN" || payout.cryptoCurrency === "BTC" ? "BITCOIN" : payout.network === "ERC20" ? "ETHEREUM" : "TRON"}</TableCell>
                                    <TableCell className={classes.tableCell}>{`${parseFloat(payout.cryptoAmount).toFixed(payout.cryptoCurrency === "BTC" ? 8 : payout.cryptoCurrency === "ETH" ? 18 : payout.cryptoCurrency === "USDT" ? 6 : payout.cryptoCurrency === "USDC" ? 6 : 2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${payout.exchangeRate}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${parseFloat(payout.currencyAmount).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${parseFloat(payout.feeMerchantAmount + payout.feeAmountChain).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${payout.isLN === true ? parseFloat(payout.currencyAmount + (payout.feeMerchantAmount + payout.feeAmountChain)).toFixed(2) : parseFloat(payout.currencyAmount - (payout.feeMerchantAmount + payout.feeAmountChain)).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Button variant="contained" disabled={disabeButton} className={classes.buttonPadding} onClick={() => RejectWithdrawal(payout.id)}>
                                            Reject
                                        </Button>
                                        <Button variant="contained" disabled={disabeButton} className={classes.buttonPaddingAccept} onClick={() => AcceptWithdrawal(payout.id)}>
                                            Accept
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
                    <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
                    <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
                </TableContainer>
            );
        }
    } else {
        return (
            <TableContainer>
                <Table className={classes.tableResponsive}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={8} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                                <TextField
                                    label="Search"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={searchChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell colSpan={8} key={"norecords1"}>
                                <div style={{ textAlign: "center" }}>No records found</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
        );
    }
};

export default WithdrawalsRequest;
