import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

//Core
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import { Box, Button, Checkbox, FormControlLabel, Grid, TableContainer, TextField, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import DateRange from "../Dialogs/DateRange";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import { DataGrid, GridToolbarExport, GridToolbarContainer } from "@material-ui/data-grid";

//Styles
import styles from "../../assets/styles/components/agentsListStyle";

//Functions
import { getDeposits, getDepositsNoDispatch } from "../../actions/agent";
import { LOGIN } from "../../constants/actionTypes";

import { useHistory } from "react-router-dom";

const options = [
    {
        status: "all",
        description: "All",
    },
    {
        status: "processed",
        description: "Accepted",
    },
    {
        status: "declined",
        description: "Rejected",
    },
    {
        status: "cancel",
        description: "Canceled",
    },
    {
        status: "expired",
        description: "Expired",
    },
    {
        status: "pending",
        description: "Pending",
    },
];

const optionsCrypto = [
    {
        crypto: "all",
        description: "All",
    },
    {
        crypto: "BTC",
        description: "BTC",
    },
    {
        crypto: "LN",
        description: "Lightning",
    },
    {
        crypto: "ETH",
        description: "ETH",
    },
    {
        crypto: "USDT",
        description: "USDT",
    },
    {
        crypto: "USDC",
        description: "USDC",
    },
];

const useStyles = makeStyles(styles);

const Deposits = (props) => {
    const classes = useStyles();
    const { agentId, merchant } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const now = moment();
    const isAdmin = useSelector((state) => state.agent?.isAdmin);
    const token = useSelector((state) => state?.login?.loginData?.token);
    const [repStartDate, setRepStartDate] = useState(new Date());
    const [repEndDate, setRepEndDate] = useState(new Date());
    const [byDate, setByDate] = useState(false);
    const [disabledDates, setDisabledDates] = useState(true);

    const handleChangeCheck = (e) => {
        e.preventDefault();
        setDisabledDates(!e.target.checked);
        setByDate(e.target.checked);
    };

    const handleStartDateChange = (date) => {
        setRepStartDate(date);
    };
    const handleEndDateChange = (date) => {
        setRepEndDate(date);
    };

    let start = 0;
    let startDate = now.startOf("day");

    let subTotCryptoAmount = 0;
    let subTotUSD = 0;
    let subTotFees = 0;
    let subTotTotal = 0;
    let subTotFreePlay = 0;

    const [length, setLength] = useState(25);
    const [page, setPage] = useState(0);
    const [depositsList, setDepositsList] = useState([]);
    const [depos, setDepos] = useState([]);
    const [totals, setTotals] = useState({});
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [status, setStatus] = useState("all");
    const [cryptoCurrency, setCryptoCurrency] = useState("all");
    const [search, setSearch] = useState("");
    const [textDateButton, setTextDateButton] = useState("Last 30 Days");
    const [openDateRange, setOpenDateRange] = useState({ isOpen: false, title: "", subTitle: "" });
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: "selection",
        },
    ]);

    const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 29)));
    const [toDate, setToDate] = useState(new Date(new Date()));

    const handleCopy = (trid) => {
        navigator.clipboard.writeText(trid);
    };

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getDeposits(agentId, merchant, start, length, startDate, search, status, cryptoCurrency, fromDate, toDate, token, (res) => {
                //console.log("RESPUESTA VERIFY TOKEN", res);
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    transferToArray(res.data);
                    setDepositsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setDepos([]);
                    setDepositsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        let size = event.target.value;
        if (event.target.value == 100) {
            size = 99;
        }
        setLength(parseInt(size));
        setPage(0);
    };

    const orientation = window.matchMedia("(max-width: 700px)").matches ? "vertical" : "horizontal";

    let columns = [];
    let tableHead = [];
    if (isAdmin) {
        columns = [
            { field: "RequestDate", headerName: "Request Date", width: 170 },
            { field: "Customer", headerName: "Customer", width: 170 },
            { field: "merchantName", headerName: "Merchant", width: 170 },
            { field: "Agent", headerName: "Agent", width: 170 },
            { field: "Type", headerName: "Type", width: 170 },
            { field: "Network", headerName: "Network", width: 170 },
            { field: "Status", headerName: "Status", width: 170 },
            { field: "CryptoAmount", headerName: "CryptoAmount", width: 170 },
            { field: "Exchange", headerName: "Exchange", width: 170 },
            { field: "USD", headerName: "$USD", width: 170 },
            { field: "Fees", headerName: "Fees", width: 170 },
            { field: "Total", headerName: "Total", width: 170 },
            { field: "TransactionId", headerName: "TransactionId", width: 170 },
        ];
        tableHead = ["Request Date", "Merchant", "Deposit Date", "Customer", "Agent", "Type", "Network", "Status", "Crypto Amount", "Exchange", "$USD", "Fees", "Total", "Free Play", "Transaction Id"];
    } else {
        columns = [
            { field: "RequestDate", headerName: "Request Date", width: 170 },
            { field: "Customer", headerName: "Customer", width: 150 },
            { field: "Agent", headerName: "Agent", width: 120 },
            { field: "Type", headerName: "Type", width: 120 },
            { field: "Network", headerName: "Network", width: 120 },
            { field: "Status", headerName: "Status", width: 120 },
            { field: "CryptoAmount", headerName: "CryptoAmount", width: 120 },
            { field: "Exchange", headerName: "Exchange", width: 120 },
            { field: "USD", headerName: "$USD", width: 120 },
            { field: "Fees", headerName: "Fees", width: 120 },
            { field: "Total", headerName: "Total", width: 120 },
            { field: "TransactionId", headerName: "TransactionId", width: 120 },
        ];
        tableHead = ["Request Date", "Deposit Date", "Customer", "Agent", "Type", "Network", "Status", "Crypto Amount", "Exchange", "$USD", "Fees", "Total", "Free Play", "Transaction Id"];
    }

    const transferToArray = (lista) => {
        const dataToExcel = [];
        const list = lista;
        var tot = 0;
        var totUsd = 0;
        var rowdat = {};

        list.forEach((row, index) => {
            rowdat = {
                id: index,
                RequestDate: `${new Date(row.createdAt).toLocaleDateString()} ${new Date(row.createdAt).toTimeString().substring(0, 5)}`,
                Customer: row.player,
                merchantName: row.merchantName,
                Agent: isAdmin ? row.masterAgent : row.agent,
                Type: row.isLN === true && row.cryptoCurrency === "BTC" ? "Lightning" : row.isLN === false && row.cryptoCurrency === "BTC" ? "BTC Mainnet" : row.cryptoCurrency,
                Network: row.isLN === true && row.network === "BITCOIN" ? "BITCOIN" : row.network === "ERC20" ? "ETHEREUM" : "TRON",
                Status: row.depositStatus === "pending" ? "Pending" : row.depositStatus === "processed" ? "Accepted" : row.depositStatus === "declined" ? "Rejected" : row.depositStatus === "cancel" ? "Canceled" : row.depositStatus === "expired" ? "Expired" : row.depositStatus,
                CryptoAmount: parseFloat(row.cryptoAmount).toFixed(row.cryptoCurrency === "BTC" ? 8 : row.cryptoCurrency === "ETH" ? 18 : row.cryptoCurrency === "USDT" ? 6 : row.cryptoCurrency === "USDC" ? 6 : 2),
                Exchange: row.exchangeRate,
                USD: row.currencyAmount.toFixed(2),
                Fees: row.isLN === true ? parseFloat(row.feeMerchant + row.feeChain).toFixed(2) : parseFloat(row.feeMerchant * row.exchangeRate).toFixed(2),
                Total: row.isLN === true || row.isLN === "true" ? parseFloat(row.currencyAmount - (row.feeMerchant + row.feeChain)).toFixed(2) : parseFloat(row.currencyAmount - parseFloat(row.feeMerchant * row.exchangeRate)).toFixed(2),
                TransactionId: row.txid_hash,
            };
            if (!isNaN(rowdat.Total)) {
                tot += parseFloat(rowdat.Total);
            }
            if (!isNaN(rowdat.USD)) {
                totUsd += parseFloat(rowdat.USD);
            }

            dataToExcel.push(rowdat);
        });
        rowdat = {
            id: "",
            RequestDate: "",
            Customer: "",
            merchantName: "",
            Agent: "",
            Type: "",
            Network: "",
            Status: "",
            CryptoAmount: "",
            Exchange: "",
            USD: `${parseFloat(totUsd).toFixed(2)}`,
            Fees: "",
            Total: `${parseFloat(tot).toFixed(2)}`,
            TransactionId: "",
        };
        dataToExcel.push(rowdat);
        setDepos(dataToExcel);
        return dataToExcel;
        //console.log("TRANSFER RESULT", dataToExcel);
    };

    useEffect(() => {
        dispatch(
            getDeposits(agentId, merchant, start, length, startDate, search, status, cryptoCurrency, fromDate, toDate, token, (res) => {
                //console.log(res.data);
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    transferToArray(res.data);
                    setDepositsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setDepos([]);
                    setDepositsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    }, [start, length, dispatch]);

    const searchChange = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
        dispatch(
            getDeposits(agentId, merchant, start, length, startDate, e.target.value, status, cryptoCurrency, fromDate, toDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    transferToArray(res.data);
                    setDepositsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setDepos([]);
                    setDepositsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };

    function MyExportButton() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const onChangeStatus = (e, v) => {
        setStatus(v.status);
        e.preventDefault();
        dispatch(
            getDeposits(agentId, merchant, start, length, startDate, search, v.status, cryptoCurrency, fromDate, toDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    transferToArray(res.data);
                    setDepositsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setDepos([]);
                    setDepositsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };

    const onChangeStatusCrypto = (e, v) => {
        setCryptoCurrency(v.crypto);
        e.preventDefault();
        dispatch(
            getDeposits(agentId, merchant, start, length, startDate, search, status, v.crypto, fromDate, toDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    transferToArray(res.data);
                    setDepositsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setDepos([]);
                    setDepositsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };

    const opDateRange = (e) => {
        e.preventDefault();
        setState([
            {
                startDate: new Date(),
                endDate: null,
                key: "selection",
            },
        ]);
        //console.log("on open", openDateRange.isOpen);
        setOpenDateRange({
            isOpen: true,
            subTitle: "",
        });
    };

    const processByDateRange = (item) => {
        //console.log("DATA RANGE", item);
        if (item.key === "selection") {
            setTextDateButton("Selection");
        } else {
            setTextDateButton(item.key);
        }
        setOpenDateRange({ ...openDateRange, isOpen: false });
        setFromDate(item.startDate);
        setToDate(item.endDate);
        dispatch(
            getDeposits(agentId, merchant, start, length, startDate, search, status, cryptoCurrency, item.startDate, item.endDate, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch({ type: LOGIN, res });
                        history.push("/");
                    }
                }
                if (res.data) {
                    transferToArray(res.data);
                    setDepositsList(res.data);
                    setTotals(res.totals);
                    setRecordsTotal(res.count);
                } else {
                    setDepos([]);
                    setDepositsList([]);
                    setTotals({});
                    setRecordsTotal(0);
                }
            })
        );
    };

    const AllDataToExcel = async () => {
        getDepositsNoDispatch(agentId, merchant, start, -1, startDate, search, status, cryptoCurrency, fromDate, toDate, token, (res) => {
            if (res.error) {
                if (res.message === "Token Expire") {
                    dispatch({ type: LOGIN, res });
                    history.push("/");
                }
            }
            if (res.data) {
                var dataArray = transferToArray(res.data);
                var newWorkbook = XLSX.utils.book_new();

                var newSheet = XLSX.utils.json_to_sheet(dataArray);

                XLSX.utils.book_append_sheet(newWorkbook, newSheet, "Sheet1");
                const excelBuffer = XLSX.write(newWorkbook, { bookType: "xlsx", type: "array" });
                const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
                saveAs(blob, "deposits.xlsx");
            }
        });
    };

    var isMobile = false;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    if (depos.length === 0) {
        return (
            <TableContainer>
                <Table className={classes.tableResponsive}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"bydate"}>
                                <Button onClick={opDateRange} className={classes.datebutton}>
                                    {textDateButton}
                                </Button>
                            </TableCell>
                            <TableCell colSpan={1} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                                <TextField
                                    label="Search"
                                    style={{ width: 150 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={searchChange}
                                />
                            </TableCell>
                            <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"status"}>
                                <Autocomplete id="status" style={{ width: 125 }} options={options} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Status" margin="normal" />} onChange={onChangeStatus} />
                            </TableCell>
                            <TableCell colSpan={6} className={classes.tableCell + " " + classes.tableHeadCell} key={"cryptoCurrency"}>
                                <Autocomplete id="crypto" style={{ width: 125 }} options={optionsCrypto} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Crypto" margin="normal" />} onChange={onChangeStatusCrypto} />
                            </TableCell>
                            <TableCell colSpan={3} className={classes.tableCell + " " + classes.tableHeadCell} key={"toexcel"}>
                                <Button onClick={AllDataToExcel} className={classes.datebutton}>
                                    {"Export Data"}
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell colSpan={11} key={"norecords1"}>
                                <div style={{ textAlign: "center" }}>No records found</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <DateRange openDateRange={openDateRange} setOpenDateRange={setOpenDateRange} state={state} setState={setState} processByDateRange={processByDateRange} />
            </TableContainer>
        );
    } else {
        return (
            <TableContainer>
                <Table className={classes.tableResponsive}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"bydate"}>
                                <Button onClick={opDateRange} className={classes.datebutton}>
                                    {textDateButton}
                                </Button>
                            </TableCell>
                            <TableCell colSpan={1} className={classes.tableCell + " " + classes.tableHeadCell} key={"search"}>
                                <TextField
                                    label="Search"
                                    style={{ width: 150 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={searchChange}
                                />
                            </TableCell>
                            <TableCell colSpan={2} className={classes.tableCell + " " + classes.tableHeadCell} key={"status"}>
                                <Autocomplete disableClearable={true} id="status" style={{ width: 125 }} options={options} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Status" margin="normal" />} onChange={onChangeStatus} />
                            </TableCell>
                            <TableCell colSpan={6} className={classes.tableCell + " " + classes.tableHeadCell} key={"cryptoCurrency"}>
                                <Autocomplete id="crypto" disableClearable={true} style={{ width: 125 }} options={optionsCrypto} getOptionLabel={(option) => option.description} renderInput={(params) => <TextField {...params} label="Crypto" margin="normal" />} onChange={onChangeStatusCrypto} />
                            </TableCell>
                            <TableCell colSpan={3} className={classes.tableCell + " " + classes.tableHeadCell} key={"toexcel"}>
                                <Button onClick={AllDataToExcel} className={classes.datebutton}>
                                    {"Export Data"}
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {depositsList.map((deposit, index) => {
                            subTotCryptoAmount += deposit.isLN === "true" ? parseFloat(deposit.cryptoAmount / 100000000) : parseFloat(deposit.cryptoAmount);
                            subTotUSD += deposit.currencyAmount;
                            subTotFees += deposit.isLN === true || deposit.isLN === "true" ? deposit.feeMerchant : deposit.feeMerchant * deposit.exchangeRate;
                            //console.log(deposit.feeMerchant, deposit.exchangeRate);
                            subTotTotal += deposit.currencyAmount - parseFloat(deposit.isLN === true || deposit.isLN === "true" ? deposit.feeMerchant : deposit.feeMerchant * deposit.exchangeRate);
                            subTotFreePlay += parseFloat(deposit.freePlayAmount ? deposit.freePlayAmount : 0);
                            return (
                                <TableRow key={index} className={classes.tableRow}>
                                    <TableCell className={classes.tableCell}>{moment(deposit.createdAt).format("MM/DD/YYYY hh:mm a")}</TableCell>
                                    {isAdmin ? <TableCell className={classes.tableCell}>{deposit.merchantName}</TableCell> : null}
                                    <TableCell className={classes.tableCell}>{deposit.confirmationDate ? moment(deposit.confirmationDate).format("MM/DD/YYYY hh:mm a") : ""}</TableCell>
                                    <TableCell className={classes.tableCell}>{deposit.player}</TableCell>
                                    <TableCell className={classes.tableCell}>{isAdmin ? deposit.masterAgent : deposit.agent}</TableCell>
                                    <TableCell className={classes.tableCell}>{deposit.isLN === true && deposit.cryptoCurrency === "BTC" ? "Lightning" : deposit.isLN === false && deposit.cryptoCurrency === "BTC" ? "BTC Mainnet" : deposit.cryptoCurrency}</TableCell>
                                    <TableCell className={classes.tableCell}>{deposit.network === "BITCOIN" || deposit.cryptoCurrency === "BTC" ? "BITCOIN" : deposit.network === "ERC20" ? "ETHEREUM" : "TRON"}</TableCell>
                                    <TableCell className={classes.tableCell}>{`${deposit.depositStatus === "pending" ? "Pending" : deposit.depositStatus === "processed" ? "Accepted" : deposit.depositStatus === "declined" ? "Rejected" : deposit.depositStatus === "cancel" ? "Canceled" : deposit.depositStatus === "expired" ? "Expired" : deposit.depositStatus}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`${parseFloat(deposit.cryptoAmount).toFixed(deposit.cryptoCurrency === "BTC" ? 8 : deposit.cryptoCurrency === "ETH" ? 18 : deposit.cryptoCurrency === "USDT" ? 6 : deposit.cryptoCurrency === "USDC" ? 6 : 2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${deposit.exchangeRate}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${parseFloat(deposit.currencyAmount).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${deposit.isLN === true ? parseFloat(deposit.feeMerchant + deposit.feeChain).toFixed(2) : parseFloat(deposit.feeMerchant * deposit.exchangeRate).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${deposit.isLN === true || deposit.isLN === "true" ? parseFloat(deposit.currencyAmount - (deposit.feeMerchant + deposit.feeChain)).toFixed(2) : parseFloat(deposit.currencyAmount - parseFloat(deposit.feeMerchant * deposit.exchangeRate)).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>{`$${parseFloat(deposit.freePlayAmount ? deposit.freePlayAmount : 0).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {`${deposit.txid_hash ? deposit.txid_hash.toString().substring(0, 6) + "..." : "N/A"}`}
                                        <IconButton aria-label="delete" onClick={() => handleCopy(deposit.txid_hash)}>
                                            <CopyIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow key={"subTotals"} className={classes.tableRow}>
                            <TableCell className={classes.tableCellSubTotal}>{"SubTotal"}</TableCell>
                            {isAdmin ? <TableCell className={classes.tableCell}></TableCell> : null}
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{/*{`₿${parseFloat(subTotCryptoAmount).toFixed(8)}`}*/}</TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotUSD).toFixed(2)}`}</TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotFees).toFixed(2)}`}</TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotTotal).toFixed(2)}`}</TableCell>
                            <TableCell className={classes.tableCellSubTotal}>{`$${parseFloat(subTotFreePlay).toFixed(2)}`}</TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                        </TableRow>
                        {!isNaN(totals.mainTotalBTC) ? (
                            <>
                                <TableRow key={"grandTotal"} className={classes.tableRow}>
                                    <TableCell className={classes.tableCellGrandotal}>{"Grand Total"}</TableCell>
                                    {isAdmin ? <TableCell className={classes.tableCell}></TableCell> : null}
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{/*{`₿${parseFloat(totals.mainTotalBTC).toFixed(8)}`}*/}</TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalUSD).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalFees).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalTotal).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCellGrandotal}>{`$${parseFloat(totals.mainTotalFreePlay).toFixed(2)}`}</TableCell>
                                    <TableCell className={classes.tableCell}></TableCell>
                                </TableRow>
                            </>
                        ) : null}
                    </TableBody>
                </Table>
                <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
                <DateRange openDateRange={openDateRange} setOpenDateRange={setOpenDateRange} state={state} setState={setState} processByDateRange={processByDateRange} />
            </TableContainer>
        );
    }
};

export default Deposits;
