import axios from "axios";

const API = axios.create({ baseURL: "https://api.flashteller.com", withCredentials: true });
//const API = axios.create({ baseURL: "http://localhost:5000", withCredentials: true });

API.interceptors.request.use((req) => {
	if (localStorage.getItem("profile")) {
		req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem("profile")).token}`;
	}
	return req;
});

export const login = (formData) => API.post("/cashier/loginagent", formData);
export const verifytoken = async (token) => await API.post("/cashier/verifytoken", { token });
