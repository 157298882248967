import { AGENT_INFO, GET_DEPOSITS, GET_WITHDRAWALS, UPDATE_AGENT, MONTHLY_DEPOSITS, WEEKLY_DEPOSITS, HOURLY_DEPOSITS, GET_MESSAGES, GET_SENT_MESSAGES, DELETE_MESSAGE, SEND_MESSAGE, GET_AGENTS_FOR_MESSAGE, GET_CUSTOMERS, UPDATE_CUSTOMER, GET_ALLAGENTS } from "../constants/actionTypes";
import { LOGIN } from "../constants/actionTypes";

import * as api from "../api/agent";

export const getAgentInfo = (agentid, merchant, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.getAgentInfo(agentid, merchant, token);
        callback(data);
        if (!data.error) {
            await dispatch({ type: AGENT_INFO, data });
        }
    } catch (error) {}
};
export const getAgentInfoSettings = (agentid, merchant, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.getAgentInfo(agentid, merchant, token);
        if (!data.error) {
            callback(data);
        }
    } catch (error) {}
};

export const getAgentInfoSecurity = (agentid, merchant, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.getAgentInfoSecurity(agentid, merchant, token);
        if (!data.error) {
            callback(data);
        }
    } catch (error) {}
};

export const verifyTwoFactCode = (info, history, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.verifyTwoFactCode(info, token);
        if (!data.error) {
            if (info.fromLogin && data.verified) {
                history.push("/admin");
                await dispatch({ type: LOGIN, data });
            } else {
                callback(data);
            }
        } else {
            callback(data);
        }
    } catch (error) {}
};

export const updateAgentSettings = (info, isPassword, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.updateAgentSettings(info, isPassword, token);
        callback(data);
    } catch (error) {}
};

export const updateAgentSecurity = (info, token, callback) => async (dispatch) => {
    try {
        //console.log("data", info, token);
        const { data } = await api.updateAgentSecurity(info, token);
        callback(data);
    } catch (error) {}
};

export const agentWithdrawal = (agentid, merchant, txid_hash, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.agentWithdrawal(agentid, merchant, txid_hash, token);

        dispatch({ type: UPDATE_AGENT, data });
        callback(data);
    } catch (error) {}
};

export const updateCustomer = (customer, id, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.updateCustomer(customer, id, token);
        dispatch({ type: UPDATE_CUSTOMER, data });
        callback(data);
    } catch (error) {}
};

export const getDeposits = (agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.getDeposits(agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token);
        callback(data);
    } catch (error) {
        //console.log("ERROR ", error);
        /*callback({
          error: true,
          data: null,
          message: error.message
        })*/
    }
};

export const getDepositsNoDispatch = async (agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token, callback) => {
    try {
        const { data } = await api.getDeposits(agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token);
        callback(data);
    } catch (error) {
        //console.log("ERROR ", error);
        /*callback({
          error: true,
          data: null,
          message: error.message
        })*/
    }
};

export const getRequestWithdrawals = (agentId, merchant, pstart, plength, startDate, search, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.getRequestWithdrawals(agentId, merchant, pstart, plength, startDate, search, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: "Error getting request withdrawals.",
        });
    }
};

export const getProcessWithdrawals = (agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.getProcessWithdrawals(agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: "Error getting request withdrawals.",
        });
    }
};

export const setWithdrawalRequestAnswer = (withdrawalId, answer, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.setWithdrawalRequestAnswer(withdrawalId, answer, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: "Error setting withdrawal answer.",
        });
    }
};

export const getWithdrawals = (agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.getWithdrawals(agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: error.message,
        });
    }
};

export const insertNewAgent = async (dataag, callback) => {
    try {
        const { data } = await api.insertNewAgent(dataag);
        callback(data);
    } catch (error) {
        callback(null);
    }
};

export const getPlayerWithdrawals = (agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.getPlayerWithdrawals(agentId, merchant, pstart, plength, startDate, search, status, cryptoCurrency, fromDate, toDate, token);
        callback(data);
    } catch (error) {
        callback({
            error: true,
            data: null,
            message: error.message,
        });
    }
};

export const monthDeposits = (tz, agentId) => async (dispatch) => {
    try {
        const { data } = await api.monthDeposits(tz, agentId);
        dispatch({ type: MONTHLY_DEPOSITS, data });
    } catch (error) {}
};

export const hourDeposits = (tz, agentId) => async (dispatch) => {
    try {
        const { data } = await api.hourDeposits(tz, agentId);
        dispatch({ type: HOURLY_DEPOSITS, data });
    } catch (error) {}
};

export const weekDeposits = (weeknumber, agentId) => async (dispatch) => {
    try {
        const { data } = await api.weekDeposits(weeknumber, agentId);
        dispatch({ type: WEEKLY_DEPOSITS, data });
    } catch (error) {}
};

export const getMessages = (agentId, pstart, plength) => async (dispatch) => {
    try {
        const { data } = await api.getMessages(agentId, pstart, plength);
        dispatch({ type: GET_MESSAGES, data });
    } catch (error) {}
};

export const getSentMessages = (agentId, pstart, plength) => async (dispatch) => {
    try {
        const { data } = await api.getSentMessages(agentId, pstart, plength);
        dispatch({ type: GET_SENT_MESSAGES, data });
    } catch (error) {}
};

export const deleteMessage = (messageId, isSent) => async (dispatch) => {
    try {
        const { data } = await api.deleteMessage(messageId);
        dispatch({
            type: DELETE_MESSAGE,
            data: { messageId: messageId, isSent: isSent },
        });
    } catch (error) {}
};

export const sendMessage = (message, callback) => async (dispatch) => {
    try {
        const { data } = await api.sendMessage(message);
        if (!data.error) {
            dispatch({ type: SEND_MESSAGE, data });
        }
        callback(data);
    } catch (error) {}
};

export const getAgentsMessage = (agentId) => async (dispatch) => {
    try {
        const { data } = await api.getAgentsMessage(agentId);
        dispatch({ type: GET_AGENTS_FOR_MESSAGE, data });
    } catch (error) {}
};

export const getCustomers = async (agentId, merchant, pstart, plength, startDate, search, token, callback) => {
    try {
        //console.log("search", agentId, merchant, pstart, plength, startDate, search);
        const { data } = await api.getCustomers(agentId, merchant, pstart, plength, startDate, search, token);
        callback(data);
    } catch (error) {}
};

export const getCustomer = (id, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.getCustomer(id, token);
        callback(data);
    } catch (error) {}
};

export const getAllAgentsList = async (agentId, pstart, plength, startDate, search, callback) => {
    try {
        const { data } = await api.getAllAgentsList(agentId, pstart, plength, startDate, search);
        callback(data);
    } catch (error) {
        callback(null);
    }
};
